module.exports = function( $ ) {

    const $self = $( this );
    const $instagramWrapper = $self.find( '.elementor-instagram' );
    const $carousel = $self.find( '.elementor-instagram-carousel' );

    if ( ! $instagramWrapper.length ) {
        return;
    }

    const options = $instagramWrapper.data( 'options' );

    const init = function() {
        if(options.api_type == 'token'){
            initTokenConnection();
        } else{
            initNoTokenConnection();
        }
    };

    const initTokenConnection = function() {

        if (elementorFrontendConfig.instagramToken == ''){
            return;
        }

        let html = "<div class='il-item "+ options.class + "'><div class='il-item-inner'>";
        html += '<a href="{{link}}" class="instagram-{{type}}" rel="noopener" target="_blank">';
        html += '<img loading="lazy" src="{{image}}" alt="{{caption}}" class="il-photo__img" width="" height="" />';
        html += '</a>';
        html += "</div></div>";

        const optionsPlugin = {
            'target': $instagramWrapper[0],
            'accessToken': elementorFrontendConfig.instagramToken,
            'template': html,
            /*
             'transform': function(item) {
                item.model.image  = item.link + 'media/?size=' + options.image_size_token;
                return item;
            },
             */
            'limit': parseInt(options.limit_token),
            'after': function(){
                if ( ! $carousel.length ) {
                    return;
                }
                initSwiper();
            }
        };

        const feed = new Instafeed(optionsPlugin);
        feed.run();

    };

    const initNoTokenConnection = function() {

        let limit = 12;
        let username;
        let tag;

        if(options.feed_type == 'user'){
            if (options.user == '' ) {
                return;
            }
            limit = options.limit_user;
            username = options.user;
        } else{
            if (options.tag == '' ) {
                return;
            }
            limit = options.limit_tag;
            tag = options.tag;
        }

        const optionsPlugin = {
            'username': username,
            'host': "https://www.instagram.com/",
            'tag': tag,
            'display_profile': false,
            'display_biography': false,
            'container': $instagramWrapper,
            'cache_time': 380,
            'image_class': options.class,
            'styling': true,
            'items': limit,
            'image_size': options.image_size,
            'callback': function(data){
                if ( ! $carousel.length ) {
                    return;
                }
                initSwiper();
            }
        };

        $.instagramFeed(optionsPlugin);

    };

    const initSwiper = function() {
        var savedOptions = $carousel.data( 'slider_options' ),
            defaultOptions = {
                rtl: parseInt(elementorFrontendConfig.is_rtl) ? true : false,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: savedOptions.slidesToShowTablet,
                            slidesToScroll: savedOptions.slidesToShowTablet
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: savedOptions.slidesToShowMobile,
                            slidesToScroll: savedOptions.slidesToShowMobile
                        }
                    }
                ]
            },

            slickOptions = $.extend( {}, defaultOptions, $carousel.data( 'slider_options' ) );
            $carousel.slick( slickOptions );
    };

    init();

};




